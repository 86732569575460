body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
}

h2, h3, h4 {
  border-bottom: 1px solid white;
}

h3 {
  width: 50%;
}

h4 {
  width: 40%;
}

h4 {
  margin-left: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=text][data-type=entry], textarea[data-type=entry] {
  background-color: transparent;
  padding: 4px 12px;
  border-radius: 12px;
  border: 1px inset white;
  width: 24em;
  color: white;
  display: inline-block;
}

textarea {
  min-height: 12vh;
}

.entry {
  margin-bottom: 2px;
}

.entry label {
  width: 8em;
  display: inline-block;
  vertical-align: top;
}

.subentry {
  margin-left: 1em;
}

.entry:last-of-type {
  margin-bottom: 0;
}

button.submit {
  background-color: transparent;
  border: 1px inset white;
  width: 100%;
  padding: 6px 12px;
  border-radius: 12px;
  color: white;
}

#result {
  margin-top: 2px;
  background-color: transparent;
  padding: 4px 12px;
  border-radius: 12px;
  border: 1px inset white;
  width: 100%;
  color: white;
  display: inline-block;
  box-sizing: border-box;
  min-height: 64vh;
}

#subject {
  margin-top: 2px;
  background-color: transparent;
  padding: 4px 12px;
  border-radius: 12px;
  border: 1px inset white;
  width: 100%;
  box-sizing: border-box;
  color: white;
  display: inline-block;
}